import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { lighten } from "polished";

const StyledTypography = styled(Typography)`
  display: inline;
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: " ";
    height: 0.05em;
    width: 100%;
    background-color: ${props => lighten(.2, props.theme.palette.secondary.main)};
  }
`;

const UnderlinedTypography = (props) => (
  <StyledTypography {...props} />
);

export default UnderlinedTypography;